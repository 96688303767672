var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "card-filter",
      attrs: { elevation: "0", width: _vm.cardWidthProp },
    },
    [
      _c(
        "div",
        { staticClass: "card-filter__content" },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c("z-autocomplete", {
                attrs: {
                  label: "Selecione o filtro",
                  items: _vm.computedFiltersOptions || [],
                  readonly: _vm.isEditingMode,
                  "item-value": "value",
                },
                on: { change: _vm.setCurrentComponent },
                model: {
                  value: _vm.currentSelectedFilter,
                  callback: function ($$v) {
                    _vm.currentSelectedFilter = $$v
                  },
                  expression: "currentSelectedFilter",
                },
              }),
              _c(
                "div",
                { staticClass: "left__body" },
                [
                  _c(_vm.currentComponent, {
                    tag: "component",
                    attrs: {
                      "current-edit-chip-prop": _vm.currentChipToEdit,
                      "has-order-chip-template-and-journey-prop":
                        _vm.alreadyHasTemplateAndJourneyOrderChip,
                    },
                    on: {
                      "select-journey": function ($event) {
                        return _vm.setCurrentInformation($event)
                      },
                      "select-template": function ($event) {
                        return _vm.setCurrentInformation($event)
                      },
                      "select-interval": _vm.setCurrentInformation,
                      "select-order": function ($event) {
                        return _vm.setCurrentInformation($event, true)
                      },
                      "select-custom-interval": _vm.selectCustomInterval,
                      "block-close-on-click": function ($event) {
                        return _vm.$emit("block-close-on-click", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          !_vm.isEditingMode
            ? _c("div", { staticClass: "right" }, [
                _c(
                  "div",
                  { staticClass: "right__header" },
                  [
                    _c("span", [_vm._v("Filtros selecionados")]),
                    _c(
                      "v-icon",
                      {
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("remove-all-filters")
                          },
                        },
                      },
                      [_vm._v(" $delete_forever_rounded")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "right__body mt-3" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.localChipsList, function (item) {
                      return _c(
                        "li",
                        { key: item.id },
                        [
                          _c("filter-chip", {
                            attrs: { "chip-info": item },
                            on: {
                              "remove-chip": function ($event) {
                                return _vm.$emit("remove-chip", $event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ])
            : _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "right__header" }, [
                  _c("span", [_vm._v("Filtros selecionados")]),
                ]),
                _c(
                  "div",
                  { staticClass: "right__body mt-3" },
                  [
                    _c("filter-chip", {
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        "chip-info": _vm.currentChipToEdit,
                        "is-editing-prop": _vm.isEditingMode,
                      },
                      on: {
                        "remove-chip": function ($event) {
                          return _vm.$emit("remove-chip", $event)
                        },
                        click: function ($event) {
                          return _vm.$emit("clicked-chip", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-filter__bottom" },
        [
          _c("v-divider", { staticClass: "my-3" }),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("z-btn", {
                attrs: {
                  text: "Fechar",
                  small: "",
                  rounded: "",
                  color: "primary",
                  width: "190",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
              _c("z-btn", {
                attrs: {
                  rounded: "",
                  primary: "",
                  text: _vm.isEditingMode ? "Atualizar" : "Aplicar filtros",
                  small: "",
                  width: "190",
                },
                on: { click: _vm.submitInfo },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "offset-y": "",
            bottom: "",
            "min-width": "300",
            height: "500",
            "close-on-content-click": false,
            "close-on-click": _vm.closeModalOnContentClick,
            eager: "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: menu, attrs }) {
                return [
                  _c(
                    "FilterInput",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            "activator-menu-prop": menu,
                            filters: _vm.chipsList,
                            "is-menu-open": _vm.showMenu,
                          },
                          on: {
                            "click:chip": _vm.onChipClick,
                            "remove-all": _vm.onRemoveAllFilters,
                            openMenu: function ($event) {
                              _vm.showMenu = !_vm.showMenu
                            },
                            removeFilter: _vm.onRemoveFilter,
                          },
                        },
                        "FilterInput",
                        attrs,
                        false
                      ),
                      menu
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c("FilterCard", {
            key: _vm.cardKey,
            attrs: {
              tempaltesListProp: _vm.tempaltesListProp,
              journeysListProp: _vm.journeysListProp,
              intervalFiltersListProp: _vm.intervalFiltersListProp,
              "filter-chips-list-prop": _vm.chipsList,
              filterOptionsProp: _vm.filterOptionsProp,
            },
            on: {
              close: function ($event) {
                _vm.showMenu = false
              },
              "remove-chip": _vm.onRemoveFilter,
              "create-chip-filter": _vm.onCreateFilterChip,
              "update-chip": _vm.onUpdateChip,
              "clicked-chip": _vm.onChipSelectedClick,
              "block-close-on-click": _vm.shouldCloseContentOnClick,
              "remove-all-filters": _vm.onRemoveAllFilters,
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          key: _vm.dialogDeleteKey,
          attrs: { width: "600" },
          model: {
            value: _vm.deleteChipDialog,
            callback: function ($$v) {
              _vm.deleteChipDialog = $$v
            },
            expression: "deleteChipDialog",
          },
        },
        [
          _c("CardDeleteChip", {
            on: {
              close: function ($event) {
                _vm.deleteChipDialog = false
              },
              "remove-child-chip": _vm.onRemoveChildChip,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
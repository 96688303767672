var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "wrapper__content" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on: tooltipIcon, attrs }) {
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("openMenu")
                                },
                              },
                            },
                            "v-icon",
                            attrs,
                            false
                          ),
                          { ..._vm.activatorMenuProp, ...tooltipIcon }
                        ),
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isMenuOpen
                                  ? "$close_rounded"
                                  : "$filter_list_rounded"
                              )
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("div", { staticClass: "tooltip-chip" }, [
                _c("span", [_vm._v("Opções de filtros")]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "middle" },
        [
          _c(
            "v-sheet",
            {
              staticClass: "mx-auto",
              staticStyle: { background: "transparent !important" },
              attrs: { "max-width": "100%" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c(
                "v-slide-group",
                { attrs: { multiple: "", "show-arrows": "" } },
                _vm._l(_vm.chipsList, function (item) {
                  return _c(
                    "v-slide-item",
                    { key: item.id },
                    [
                      _c(
                        "v-tooltip",
                        {
                          staticStyle: { "border-radius": "25px !important" },
                          attrs: {
                            bottom: item.chip_computed_labels.length >= 200,
                            top: item.chip_computed_labels.length < 200,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on: tooltip, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "chip mx-1",
                                            staticStyle: {
                                              "max-width": "200px",
                                            },
                                            style: {
                                              backgroundColor:
                                                item.chip_type == "interval"
                                                  ? "#14AE5C"
                                                  : "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.$emit(
                                                  "click:chip",
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        { ...tooltip, ..._vm.activatorMenuProp }
                                      ),
                                      [
                                        _c("div", { staticClass: "left" }, [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.chip_title) + " ="
                                            ),
                                          ]),
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                item.chip_computed_labels ||
                                                  "sem info"
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "right" },
                                          [
                                            item.chip_type != "interval"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      "font-size":
                                                        "1rem !important",
                                                    },
                                                    attrs: { color: "#ffff" },
                                                    on: {
                                                      click: function ($event) {
                                                        if (
                                                          $event.target !==
                                                          $event.currentTarget
                                                        )
                                                          return null
                                                        $event.stopPropagation()
                                                        return _vm.$emit(
                                                          "removeFilter",
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " $remove_circle_outline_rounded "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("div", { staticClass: "tooltip-chip" }, [
                            _c("span", [
                              _vm._v(_vm._s(item.chip_title) + " ="),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(item.chip_computed_labels)),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "d-flex align-center justify-center" }, [
          _c(
            "div",
            { staticClass: "delete-icon" },
            [
              _c(
                "z-tooltip",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "z-icon",
                            _vm._g(
                              {
                                directives: [
                                  { name: "ripple", rawName: "v-ripple" },
                                ],
                                staticStyle: { cursor: "pointer" },
                                attrs: { color: "primary", size: "21" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.removeAllFilters.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              on
                            ),
                            [_vm._v(" $delete_forever_rounded ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" Remover todos os filtros ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      {
        staticClass: "chip",
        style: {
          backgroundColor:
            _vm.chipInfo.chip_type == "interval" ? "#14AE5C" : "",
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("click", _vm.chipInfo)
          },
        },
      },
      _vm.chipPropagation
    ),
    [
      !_vm.isEditingFiltersProp
        ? _c("div", { staticClass: "left" }, [
            _c("span", [_vm._v(_vm._s(_vm.chipInfo.chip_title) + " =")]),
            _c("strong", [_vm._v(_vm._s(_vm.chipInfo.chip_computed_labels))]),
          ])
        : _vm._e(),
      _vm.isEditingFiltersProp
        ? _c("div", { staticClass: "left" }, [
            _c("strong", [_vm._v(_vm._s(_vm.chipInfo.chip_child_label))]),
          ])
        : _vm._e(),
      !_vm.isEditingProp && _vm.chipInfo.chip_type != "interval"
        ? _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "v-icon",
                {
                  attrs: { color: "#ffff", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("remove-chip", _vm.chipInfo)
                    },
                  },
                },
                [_vm._v("$remove_circle_outline_rounded")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
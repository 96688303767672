import { render, staticRenderFns } from "./cardDeleteChip.vue?vue&type=template&id=1180fb44&scoped=true&"
import script from "./cardDeleteChip.vue?vue&type=script&lang=js&"
export * from "./cardDeleteChip.vue?vue&type=script&lang=js&"
import style0 from "./cardDeleteChip.vue?vue&type=style&index=0&id=1180fb44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1180fb44",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kalil/Documents/projects/zoox/data-to-act-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1180fb44')) {
      api.createRecord('1180fb44', component.options)
    } else {
      api.reload('1180fb44', component.options)
    }
    module.hot.accept("./cardDeleteChip.vue?vue&type=template&id=1180fb44&scoped=true&", function () {
      api.rerender('1180fb44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Shared/filter/cardDeleteChip.vue"
export default component.exports
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "wrapper__header" },
      [
        _c("span", [_vm._v("EDITAR FILTROS DE " + _vm._s(_vm.title))]),
        _c(
          "v-icon",
          {
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(" $close_rounded")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "wrapper__body" }, [
      _c(
        "div",
        { staticClass: "wrapper__body__list" },
        [
          _vm._l(_vm.currentChip.childs || [], function (item) {
            return [
              _c(
                "div",
                { key: item.id, staticClass: "item" },
                [
                  _c("FilterChip", {
                    attrs: {
                      "chip-info": item,
                      "is-editing-filters-prop": true,
                    },
                    on: { "remove-chip": _vm.removeChip },
                  }),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ]),
    _c(
      "div",
      { staticClass: "wrapper__footer d-flex justify-end" },
      [
        _c("z-btn", {
          attrs: { text: "Fechar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
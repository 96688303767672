<template>
  <v-card elevation="0" class="card-filter" :width="cardWidthProp">
    <div class="card-filter__content">
      <div class="left">
        <z-autocomplete
          v-model="currentSelectedFilter"
          label="Selecione o filtro"
          :items="computedFiltersOptions || []"
          :readonly="isEditingMode"
          item-value="value"
          @change="setCurrentComponent"
        />

        <div class="left__body">
          <component
            :is="currentComponent"
            :current-edit-chip-prop="currentChipToEdit"
            :has-order-chip-template-and-journey-prop="
              alreadyHasTemplateAndJourneyOrderChip
            "
            @select-journey="setCurrentInformation($event)"
            @select-template="setCurrentInformation($event)"
            @select-interval="setCurrentInformation"
            @select-order="setCurrentInformation($event, true)"
            @select-custom-interval="selectCustomInterval"
            @block-close-on-click="$emit('block-close-on-click', $event)"
          />
        </div>
      </div>

      <v-divider vertical />

      <div v-if="!isEditingMode" class="right">
        <div class="right__header">
          <span>Filtros selecionados</span>
          <v-icon color="primary" @click="$emit('remove-all-filters')">
            $delete_forever_rounded</v-icon
          >
        </div>

        <div class="right__body mt-3">
          <ul>
            <li v-for="item in localChipsList" :key="item.id">
              <filter-chip
                :chip-info="item"
                @remove-chip="$emit('remove-chip', $event)"
              />
            </li>
          </ul>
        </div>
      </div>

      <div v-else class="right">
        <div class="right__header">
          <span>Filtros selecionados</span>
        </div>

        <div class="right__body mt-3">
          <filter-chip
            style="cursor: pointer"
            :chip-info="currentChipToEdit"
            :is-editing-prop="isEditingMode"
            @remove-chip="$emit('remove-chip', $event)"
            @click="$emit('clicked-chip', $event)"
          />
        </div>
      </div>
    </div>

    <div class="card-filter__bottom">
      <v-divider class="my-3" />

      <div class="actions">
        <z-btn
          text="Fechar"
          small
          rounded
          color="primary"
          width="190"
          @click="$emit('close')"
        />
        <z-btn
          rounded
          primary
          :text="isEditingMode ? 'Atualizar' : 'Aplicar filtros'"
          small
          width="190"
          @click="submitInfo"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
const _ = require("lodash");
import { FILTERS_ENUM } from "./utils";
import filterChip from "./filterChip.vue";
// import JourneyList from "./components/journeyList.vue";
// import TemplateList from "./components/templateList.vue";
// import IntervalList from "./components/intervalList.vue";
export default {
  props: {
    cardWidthProp: { type: [Number, String], default: 600 },
    journeysListProp: { type: Array, default: null, required: false },
    tempaltesListProp: { type: Array, default: null, required: false },
    intervalFiltersListProp: { type: Array, default: null, required: false },
    filterChipsListProp: { type: Array, default: null, required: true },
    filterOptionsProp: { type: Array, default: null, required: true },
  },
  components: {
    filterChip,
    // JourneyList,
    // TemplateList,
    // IntervalList,
    // interval: () => import("./components/intervalList.vue"),
    // templates: () => import("./components/templateList.vue"),
    // journey: () => import("./components/journeyList.vue"),
  },

  computed: {
    alreadyHasComponentWithTypeTemplate() {
      const order_list = this.localChipsList.filter((e) => e.chip_type === "order");

      const template_type = this.hasChipWithThisType(order_list, "template");

      return template_type.length > 0;
    },

    alreadyHasComponentWithTypeJourney() {
      const order_list = this.localChipsList.filter((e) => e.chip_type === "order");

      const template_type = this.hasChipWithThisType(order_list, "journey");

      return template_type.length > 0;
    },

    alreadyHasTemplateAndJourneyOrderChip() {
      let d = false;
      if (
        this.alreadyHasComponentWithTypeTemplate &&
        this.alreadyHasComponentWithTypeJourney
      )
        d = true;
      return d;
    },

    computedFiltersOptions() {
      const infos = _.cloneDeep(this.filterOptionsProp) || [];

      if (!this.isEditingMode) {
        const interval_index = infos.findIndex((d) => d.value === "interval");
        if (interval_index >= 0) infos.splice(interval_index, 1);
      }

      return infos;
    },
  },

  watch: {
    filterChipsListProp: {
      handler() {
        this.localChipsList = _.cloneDeep(this.filterChipsListProp);
      },
      deep: true,
    },

    currentSelectedInterval: {
      handler(nv) {
        if (nv == 6) this.$emit("block-close-on-click", false);
        else {
          this.$emit("block-close-on-click", true);
          this.customInterval = "";
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.$store.getters["app/$currentEditChip"]) {
      this.currentChipToEdit = _.cloneDeep(this.$store.getters["app/$currentEditChip"]);
      this.currentSelectedFilter = this.currentChipToEdit.chip_type;
      this.isEditingMode = true;

      this.setCurrentComponent();
    }
    this.localChipsList = _.cloneDeep(this.filterChipsListProp);
  },

  data() {
    return {
      currentComponent: null,
      customInterval: "",
      localChipsList: [],
      isEditingMode: false,
      currentChipToEdit: null,
      currentChipToCreate: null,
      currentSelectedFilter: "",
      currentSelectedInterval: null,
      currentSelectedJourney: null,
      currentSelectedTemplate: null,
      items: [
        { text: "Jornada", value: "journey" },
        { text: "Template", value: "templates" },
        { text: "Intervalo de tempo", value: "interval" },
        { text: "Orndenação", value: "order" },
      ],
    };
  },

  methods: {
    hasChipWithThisType(order_list, type) {
      const template_type = order_list.flatMap((el) =>
        el.childs.filter((child) => child.chip_assistant.includes(type))
      );

      return template_type;
    },

    async setCurrentComponent() {
      const d = this.computedFiltersOptions.find(
        (d) => d.value == this.currentSelectedFilter
      );
      // const component = await import(`${d.child_component}`)

      // IMPORTAÇÃO DO COMPOENNTE A SER CARREGADO NO CARD DE FORMA DINÂMICA
      const component = await import(`./components/${d.component_name}`);
      this.currentComponent = component.default;
    },

    selectCustomInterval(value, interval) {
      this.customInterval = interval;
      this.setCurrentInformation(value);
    },

    createChip() {
      return {
        id: this.generateId(),
        childs: [],
        chip_computed_labels: "",
        chip_type: this.currentSelectedFilter,
        chip_title: FILTERS_ENUM[this.currentSelectedFilter],
      };
    },

    createChildChip(chip, info) {
      let DATES = [];

      if (chip.chip_type === "interval") {
        DATES = info.textDate.split("até");
      }

      return {
        id: this.generateId(),
        chip_info_child_id: info.id || info.value,
        chip_child_label: info.name || info.chip_child_label || "",
        chip_father_id: chip.id,
        chip_text_date: info.textDate || "",
        chip_assistant: info.chip_assistant || "",
        chip_start_date: DATES.length
          ? moment(DATES[0].trim(), "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        chip_end_date: DATES.length
          ? moment(DATES[1].trim(), "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
      };
    },

    submitInfo() {
      if (this.isEditingMode) this.$emit("update-chip", this.currentChipToEdit);
      else this.$emit("create-chip-filter", this.localChipsList);
    },

    generateId() {
      return Math.random().toString(16).slice(2);
    },

    async setCurrentInformation(info, should_replace_child = false) {
      if (!this.isEditingMode) {
        switch (this.currentSelectedFilter) {
          case "templates":
            await this.setInformationToTemplate(info);
            break;
          case "journey":
            await this.setInformationToJourney(info);
            break;
          case "interval":
            await this.setInformationToInterval(info);
            break;
          default:
            this.setDefaultInformation(info);
            break;
        }
      } else {
        switch (this.currentSelectedFilter) {
          case "templates":
            await this.setInformationToTemplate(info, true);
            break;
          case "journey":
            await this.setInformationToJourney(info, true);
            break;
          case "interval":
            await this.setInformationToInterval(info, true);
            break;
          default:
            this.setDefaultInformation(info, true, should_replace_child);
            break;
        }
      }
    },

    async setDefaultInformation(
      info,
      isEditingChip = false,
      should_replace_child = false
    ) {
      if (!isEditingChip) {
        const chip = this.createChip();

        chip.childs.push(info);

        chip.chip_computed_labels = await this.joinChipsLabel(chip.childs);

        // preencher todos os filhos com o id do pai
        chip.childs.forEach((e) => {
          e.chip_father_id = chip.id;
        });

        this.currentChipToCreate = chip;

        this.localChipsList.push(this.currentChipToCreate);
      } else {
        if (should_replace_child) {
          const chip = { ...this.currentChipToEdit };

          chip.childs = [];

          const child_chip = this.createChildChip(chip, info);

          chip.childs.push(child_chip);

          chip.chip_computed_labels = await this.joinChipsLabel(chip.childs);

          this.currentChipToEdit = chip;
        } else {
          console.log("create logic to insert item on child array");
        }
      }
    },

    async setInformationToJourney(info, isEditingChip = false) {
      if (!isEditingChip) {
        const chip = this.createChip();

        const child_chip = await this.createChildChip(chip, info);

        chip.childs.push(child_chip);

        chip.chip_computed_labels = await this.joinChipsLabel(chip.childs);

        this.currentChipToCreate = chip;

        this.localChipsList.push(this.currentChipToCreate);

        this.currentSelectedJourney = null;

        this.currentSelectedFilter = null;

        this.currentComponent = null;
      } else {
        const chip = { ...this.currentChipToEdit };

        const child_chip = await this.createChildChip(chip, info);

        chip.childs.push(child_chip);

        chip.chip_computed_labels = await this.joinChipsLabel(chip.childs);

        this.currentChipToEdit = chip;
      }

      return;
    },

    async setInformationToTemplate(info, isEditingChip = false) {
      if (!isEditingChip) {
        const chip = this.createChip();

        const child_chip = await this.createChildChip(chip, info);

        chip.childs.push(child_chip);

        chip.chip_computed_labels = await this.joinChipsLabel(chip.childs);

        this.currentChipToCreate = chip;

        this.localChipsList.push(this.currentChipToCreate);

        this.currentSelectedTemplate = null;

        this.currentSelectedFilter = null;

        this.currentComponent = null;
      } else {
        const chip = { ...this.currentChipToEdit };

        const child_chip = await this.createChildChip(chip, info);

        chip.childs.push(child_chip);

        chip.chip_computed_labels = await this.joinChipsLabel(chip.childs);

        this.currentChipToEdit = chip;
      }

      return;
    },

    async setInformationToInterval(info, isEditingChip = false) {
      if (!isEditingChip) {
        const chip = this.createChip();

        // se meu chip de tempo for diferente da opção 'personalizado'
        if (info != 6) {
          const INFO_CHIP = this.intervalFiltersListProp.find((c) => c.value == info);

          const child_chip = await this.createChildChip(chip, INFO_CHIP);

          chip.childs.push(child_chip);

          chip.chip_computed_labels = await this.joinChipsLabel(chip.childs);

          this.currentChipToCreate = chip;

          // verificar se minha lista pai já tem algum chip do tipo de intervalo
          const has_some_date_chip = this.localChipsList.some(
            (d) => d.chip_type == "interval"
          );

          // caso não tenha, inserir chip na lista
          if (!has_some_date_chip) this.localChipsList.push(this.currentChipToCreate);

          this.currentSelectedInterval = null;

          this.currentSelectedFilter = null;

          this.currentComponent = null;
        }

        // se meu chip for da opção 'personalizado'
        else {
          if (this.customInterval) {
            chip.childs.push({
              id: this.generateId(),
              chip_info_child_id: null,
              chip_child_label: `Período: ${this.customInterval[0]} / ${this.customInterval[1]}`,
              chip_father_id: chip.id,
              chip_text_date: `${this.customInterval[0]} até ${this.customInterval[1]}`,
              chip_assistant: "",
              chip_start_date: moment(this.customInterval[0]).format("YYYY-MM-DD"),
              chip_end_date: moment(this.customInterval[1]).format("YYYY-MM-DD"),
            });

            chip.chip_computed_labels = await this.joinChipsLabel(chip.childs);

            this.currentChipToCreate = chip;

            // verificar se minha lista pai já tem algum chip do tipo de intervalo
            const has_some_date_chip = this.localChipsList.some(
              (d) => d.chip_type == "interval"
            );

            // caso não tenha, inserir chip na lista
            if (!has_some_date_chip) this.localChipsList.push(this.currentChipToCreate);

            this.currentSelectedFilter = null;

            this.currentSelectedInterval = null;

            this.currentComponent = null;
          }
        }
      } else {
        // caso o chip não seja com data personalizada
        if (info != 6) {
          // ao editar as informações do chip, vamos sobrescrever as informações ao invés de adicionar
          const info_chip = this.intervalFiltersListProp.find((c) => c.value == info);

          const chip = { ...this.currentChipToEdit };

          const child_chip = await this.createChildChip(chip, info_chip);

          // resetar a lista para inserção apenas da nova informação
          chip.childs = [];

          chip.childs.push(child_chip);

          chip.chip_computed_labels = await this.joinChipsLabel(chip.childs);

          this.currentChipToEdit = chip;
        } else {
          const chip = { ...this.currentChipToEdit };

          // caso tenha selecionado o intervalo de tempo
          if (this.customInterval) {
            // resetar a lista para inserção apenas da nova informação
            chip.childs = [];

            chip.childs.push({
              id: this.generateId(),
              chip_info_child_id: 6,
              chip_child_label: `Período: ${this.customInterval[0]} / ${this.customInterval[1]}`,
              chip_father_id: chip.id,
              chip_text_date: `${this.customInterval[0]} até ${this.customInterval[1]}`,
              chip_start_date: moment(this.customInterval[0]).format("YYYY-MM-DD"),
              chip_end_date: moment(this.customInterval[1]).format("YYYY-MM-DD"),
              chip_assistant: "",
            });

            chip.chip_computed_labels = await this.joinChipsLabel(chip.childs);

            this.currentChipToEdit = chip;
          }
        }
      }
    },

    joinChipsLabel(chips) {
      const ARR = chips.map((d) => d.chip_child_label);
      const LABEL = ARR.join("; ");
      return LABEL;
    },
  },

  destroyed() {
    this.currentChipToEdit = null;
    this.currentChipToCreate = null;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.card-filter {
  padding: 0.5rem;
  &__content {
    width: 100%;
    height: 50vh;
    display: flex;
    gap: 1rem;
    padding: 0.5rem 0 0;

    .left,
    .right {
      flex: 1 1 100%;
      text-align: center;
    }
    .right {
      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        span {
          font-weight: 500;
          font-size: 0.75rem;
        }
      }
      &__body {
        height: 90%;
        overflow-y: auto;
        @include trackScrollBar;

        ul {
          height: 100%;
          padding: 0.5rem;
          li {
            list-style: none;
            &:not(:last-child) {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }

  &__bottom {
    padding: 0.5rem 0;
    .actions {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }
}
</style>
